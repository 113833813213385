.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Sidebar {
    height: -webkit-calc(100vh);
    height: -moz-calc(100vh);
    min-height: -webkit-calc(100vh);
    min-height: -moz-calc(100vh);
    max-height: -webkit-calc(100vh);
    max-height: -moz-calc(100vh);
}

.RootContentBox {
    min-height: -webkit-calc(100vh);
    min-height: -moz-calc(100vh);
    max-height: -webkit-calc(100vh);
    max-height: -moz-calc(100vh);
}

.SidebarOverlay {
    height: -webkit-calc(100vh);
    height: -moz-calc(100vh);
    width: -webkit-calc(100vw);
    width: -moz-calc(100vw);
}